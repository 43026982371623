// @vendors
import React from 'react'
import { Button, Form, Input } from "antd";
import { useState, useContext } from "react";
import { recotizadores } from 'authConfig';
import { Microservice } from "services/microservice";
import { PortalAgentesContext, PortalAgentesFormContext } from 'context/context';

// @styles 
import './styles.scss'
import ChooseOnlyUser from 'components/choose-only-user';


export default function ContinueProcess({ info, codeProduct }) {
    const [value, setValue] = useState("");
    const [loadBtn, setLoadBtn] = useState(false);
    const data = info && info.items && info.items[0] ? info.items[0] : {};
    const [openChooseOnlyUser, setOpenChooseOnlyUser] = useState(false);
    const { value: { user } } = useContext(PortalAgentesContext);
    const { value: { selectedProduct, allowedUsers } } = useContext(PortalAgentesFormContext);
    const regex = '^[0-9]*$';

    const selectedUser = async (cdUsuario) => {
        const agentValue = allowedUsers.find(x => x.cdUsuario === cdUsuario)
        const token = await generateToken(agentValue || {});
        data.linktoredirect && window.open(`${data.linktoredirect}${token}`, '_blank');
    }

    const generateToken = async (agent) => {
        const CryptoJS = require("crypto-js");
        const dataEncrypt = CryptoJS.AES.encrypt(JSON.stringify({
            cotizacionId: value, usuarioId: agent.cdUsuario, email: user.email,
            oficinaId: agent.cdOficina, ramoId: selectedProduct.claveProducto
        }), recotizadores.key).toString();
        const data = { value: dataEncrypt, origen: 'externo' };
        const res = await Microservice(data, user.idToken, 'perfilamiento');
        return res.token;
    }

    const openModalUsers = () => {
        setOpenChooseOnlyUser(true);
    }

    return (
        <>
            <ChooseOnlyUser
                users={allowedUsers}
                visible={openChooseOnlyUser}
                onClose={() => setOpenChooseOnlyUser(false)}
                selectUser={(cdUsuario) => selectedUser(cdUsuario)}
            />
            <div className='process__container' id={info.idcontent}>
                <div className='process__container-content'>
                    <div className='homepage__container-title left-title'>
                        <p>{info.primary && info.primary.title ? info.primary.title : ""}</p>
                    </div>
                    <div className="process__container-content__actions">
                        <div className="process__container-content__actions-input">
                            <Form.Item label={data.labelfield ? data.labelfield : ""} className="process__container-content__actions-input-home">
                                <Input disabled={loadBtn} maxLength={13}
                                    placeholder={data.placeholderfield ? data.placeholderfield : ""}
                                    onChange={(e) => setValue(e.target.value)}
                                />
                            </Form.Item>
                        </div>
                        {data.titlebtn && (
                            <Button
                                onClick={openModalUsers}
                                loading={loadBtn}
                                className='ant-btn solid c-3 ant-btn-size-xs'
                                disabled={!value || value.length < 4 || !(new RegExp(regex).test(value))}
                            >
                                <strong>{data.titlebtn}</strong>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}