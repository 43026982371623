// @vendors
import React, { useEffect } from 'react';

// @constants
import { HOMEPAGE } from 'constants/site';
import { Helmet } from 'react-helmet';

// @hooks
import useByUID from 'hooks/useByUID';

// @components
import Banner from 'components/solution-detail/banner';
import Slice from './slice';

const SolutionDetail = ({ match }) => {
	
	const uid = match.params.uid;
	const [prismicData, notFound] = useByUID('page', 'solution_detail', uid, { historical: null });
     
	if (prismicData.page) {
		const detail = prismicData.page
		const primary = {
			group: detail.data.group,
			name: detail.data.name,
			icon: detail.data.icon,
			icon_image: detail.data.image_icon,
			description: detail.data.description,
			image: detail.data.image,
			uid: detail.uid,
			code: detail.data.codigo_solucion
		}

		return (
			<>
				<Helmet>
					<title>{primary.name}, seguro para {primary.group} - {HOMEPAGE.title}</title>
				</Helmet>
				<Banner data={primary} />
				<Slice slices={detail.data.body} code={detail.data.codigo_solucion} />
			</>
		)
	} else if (notFound) {
		return 'Not found'
	}

	return null
}

export default SolutionDetail