import React from 'react'
import { RichText } from 'prismic-reactjs'
import './styles.scss'

const Protects = ({ info }) => {
    return (
        <section className="sec__default sec__default-solution c-gr">
            <div className="container">
                <h2
                    id='seguro-detalle-protege-title' className="title__ppal title__ppal--center title__ppal--sm title__ppal--dr title__ppal--line title__ppal--line-c-3 title__ppal--extra-bold">
                    <strong>
                        {RichText.asText(info.primary.title)}
                    </strong>
                </h2>
                <ul className="list__protects">
                    {info.items.map((item, index) => (
                        <li id={`seguro-detalle-protege-item-${index}`} className="list__protects-item" key={RichText.asText(item.title)}>
                            <img id={`seguro-detalle-protege-item-${index}-image`} src={item.image.url} alt={item.image.alt} className="list__protects-item--image" />
                            <h3 className="list__protects-item--title" id={`seguro-detalle-protege-item-${index}-title`}>
                                {RichText.asText(item.title)}
                            </h3>
                            <p className="list__protects-item--parag" id={`seguro-detalle-protege-item-${index}-parag`}>
                                {RichText.asText(item.description1)}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default Protects