import { loginRequest, microservice } from "authConfig";
import jwt_decode from "jwt-decode";
import moment from "moment";
import "moment/locale/es-mx";
moment.locale("es-mx");

const Microservice = async (
  bodyRequest,
  idToken,
  path = "services",
  instance,
  accounts,
  isFile
) => {
  idToken = await tokenRenew(idToken || sessionStorage.getItem("idToken"), instance, accounts);

  const requestOptions = {
    method: "POST",
    headers: {
      "Strict-Transport-Security": "max-age=31536000",
      "Content-Security-Policy": "default-src 'self'",
      "X-Frame-Options": "DENY",
      "X-Content-Type-Options": "nosniff",
      "Referrer-Policy": "no-referrer",
      "Feature-Policy": "microphone 'none'",
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(bodyRequest),
  };

  try {
    const response = await fetch(`${microservice.url}/${path}`, requestOptions);
    if (isFile) {
      if (response.status > 299) return await response.json();

      const blob = await response.blob();
      return blob;
    }

    let result = await response.json();
    if (result !== null) {
      result.status = response.status;
    }
    return result;
  } catch (error) {
    console.log(`Error Microservice`, error);
  }
};

export const tokenRenew = async (token, instance, accounts) => {
  var decode = jwt_decode(token);
  const expirationTime = moment.unix(decode.exp);
  const timeNow = moment();
  let newToken = token;

  if (timeNow.isAfter(expirationTime)) {
    const request = { ...loginRequest, account: accounts[0] };
    const response = await instance.acquireTokenSilent(request);
    newToken = response.idToken;
    sessionStorage.setItem("idToken", newToken);
  }
  return newToken;
};

export { Microservice };
