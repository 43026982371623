import React from 'react'
import { RichText } from 'prismic-reactjs'
import './styles.scss'
import DotsCSS from 'components/dots-css'

const Risks = ({ info, index }) => {
    return (
        <section className="sec__default sec__default-risks" id={`seguro-detalle-risks-${index}`}>
            <div className="container">
                <h2  id={`seguro-detalle-risks-${index}-title`} className="title__ppal title__ppal--center title__ppal--sm title__ppal--dr title__ppal--line title__ppal--line-c-3 title__ppal--extra-bold">
                    <strong>
                        {RichText.asText(info.primary.title)}
                    </strong>
                </h2>
                <ul className="list__risks">
                    {info.items.map((item, k) => (
                        <li className="list__risks-item" id={`seguro-detalle-risks-${index}-item-${k}`} key={RichText.asText(item.title)}>
                            <div className="list__risks-item--icon">
                                <DotsCSS type="solid" color="c-gr" quantity={3} />
                                <em className={`fal fa-${RichText.asText(item.icon)}`} id={`seguro-detalle-risks-${index}-item-${k}-icon`} />
                            </div>
                            <div className="list__risks-item--description">
                                <h4 className="list__risks-item--title" id={`seguro-detalle-risks-${index}-item-${k}-title`}>
                                    {RichText.asText(item.title)}
                                </h4>
                                <div className="list__risks-item--parag" id={`seguro-detalle-risks-${index}-item-${k}-parag`}>
                                    <RichText render={item.description1} />
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default Risks