import React, { useContext, useEffect, useState } from "react";
import { differenceInCalendarMonths, compareAsc, format } from "date-fns";
import { Microservice } from "services/microservice";
import { Form, Button, Row, Col } from "antd";
import moment from "moment";
import SliceFields from "./slice-fields";
import { PortalAgentesContext, PortalAgentesFormContext } from "context/context";
import { cotizacion } from "authConfig";
import { useMsal } from "@azure/msal-react";

const RenderMessage = ({ message = "" }) => {
  const hasSpan = message.includes("{") && message.includes("}");

  if (!hasSpan) return <p>{message}</p>;

  const [init, span] = message.split("{");
  const [center, end] = span.split("}");

  return (
    <p>
      {init}
      <span style={{ color: "#f97c7a" }}>{center}</span>
      {end}
    </p>
  );
};

const FormDinamic = ({ data, closeModal }) => {
  const {
    value: { user },
  } = useContext(PortalAgentesContext);
  const {
    handle: { customRequest, setDisabledQuotationButton },
    value: { selectedProduct, options, disabledQuotationButton },
  } = useContext(PortalAgentesFormContext);
  const fields = data[0].data.fields;
  const { instance, accounts } = useMsal();
  const [form] = Form.useForm();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const salePointValue = Form.useWatch("punto-de-venta", form);
  const taxProfileValue = Form.useWatch("perfil-de-tarifa", form);
  const subGroupValue = Form.useWatch("subgrupo", form);
  const agentValue = Form.useWatch("agente", form);
  const handleShowAlert = (response) => {
    const { dniValidity = {}, policyRcValidity = {} } = response.data;
    if (!dniValidity?.endDate && !policyRcValidity?.endDate) return setShowAlert(false);
    const isDniNearToExpire =
      Math.abs(differenceInCalendarMonths(new Date(), new Date(dniValidity?.endDate))) <= 3;
    const isPolicyNearToExpire =
      Math.abs(differenceInCalendarMonths(new Date(), new Date(policyRcValidity?.endDate))) <= 3;

    const isExpiredDni = compareAsc(new Date(dniValidity?.endDate), new Date()) === -1;
    const isExpiredPolicy = compareAsc(new Date(policyRcValidity?.endDate), new Date()) === -1;

    const messages = [];
    if (isExpiredDni && isExpiredPolicy) {
      messages.push(
        "Tu cédula de agente y tu póliza de RC se encuentran vencidas, realiza la renovación del documento y una vez realizada, contacta a tu ejecutivo para actualizar el estatus en el sistema."
      );
    } else if (isExpiredDni || isExpiredPolicy) {
      if (isExpiredDni) {
        messages.push(
          "Tu cédula de agente se encuentra vencida, realiza la renovación del documento; una vez realizada, contacta a tu ejecutivo para actualizar el estatus en el sistema."
        );
      }
      if (isExpiredPolicy) {
        messages.push(
          "Tu póliza de RC se encuentra vencida, realiza la renovación del documento y una vez realizada contacta a tu ejecutivo para actualizar el estatus en el sistema."
        );
      }
    } else if (isDniNearToExpire || isPolicyNearToExpire) {
      if (isDniNearToExpire) {
        const formatedDate = format(new Date(dniValidity?.endDate), "dd/MM/yyyy");
        messages.push(
          `Tu cédula vence dentro de 3 meses el día {${formatedDate}}, recuerda realizar su renovación; una vez realizada, contacta a tu ejecutivo para actualizar el estatus en el sistema.`
        );
      }
      if (isPolicyNearToExpire) {
        const formatedDate = format(new Date(policyRcValidity?.endDate), "dd/MM/yyyy");
        messages.push(
          `Tu póliza de RC vence dentro de 3 meses el día {${formatedDate}}, recuerda realizar su renovación; una vez realizada, contacta a tu ejecutivo para actualizar el estatus en el sistema.`
        );
      }
    } else {
      messages.push(`Tu cédula vence el {${format(new Date(dniValidity?.endDate), "dd/MM/yyyy")}}`);
      messages.push(
        `Tu póliza de RC vence el {${format(new Date(policyRcValidity?.endDate), "dd/MM/yyyy")}}`
      );
    }

    setAlertMessage(messages);
    setShowAlert(true);
  };

  function cleanAlert() {
    setShowAlert(false);
    setAlertMessage([]);
  }
  useEffect(() => {
    if (selectedProduct) {
      if (selectedProduct.atrPuntoVentaId && selectedProduct.claveProducto) {
        if (
          selectedProduct.atrPuntoVentaId.length > 0 &&
          selectedProduct.claveProducto.length > 0
        ) {
          options && options.agente && options.agente.length > 0 && validate();
        }
      }
    }
  }, [options]);

  useEffect(cleanAlert, [salePointValue, taxProfileValue, subGroupValue, agentValue]);

  const validate = async () => {
    setLoading(true);
    const requestOptions = JSON.parse(process.env.REACT_APP_OPTIONS_VALIDATE_RC);
    const issueDate = moment().format("YYYY-MM-DD");
    let url = `${requestOptions.url}?issueDate=${issueDate}&salesPoint=${
      selectedProduct && selectedProduct.atrPuntoVentaId
    }&productId=${selectedProduct && selectedProduct.claveProducto}`;
    cleanAlert();
    await customRequest({ url: url, method: requestOptions.method, body: {} })
      .then((res) => {
        handleShowAlert(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error servicio Agentes", err);
      });
  };

  const redirectCotizacion = () => {
    if (selectedProduct.goCotizador) {
      generateToken();
      closeModal();
    } else {
      const parameters = `?portal=${selectedProduct.report}&username=${user.selectedUser}&redirecturi=${selectedProduct.redirectUri}&loginType=B2CAuth&secret=${cotizacion.secret}`;
      window.open(`${cotizacion.url}${parameters}`, "_blank");
      closeModal();
    }
  };

  const generateToken = async () => {
    try {
      let dataSuccess = true;
      for (const key in selectedProduct) {
        if (Object.hasOwnProperty.call(selectedProduct, key)) {
          if (selectedProduct[key] === null) {
            dataSuccess = false;
            break;
          }
        }
      }
      if (dataSuccess) {
        const body = {
          usuarioId: user.selectedUser,
          clavesAgente: user.userProfile.agentes,
          atrPuntoVentaId: selectedProduct.puntoVenta,
          atrGrupoId: selectedProduct.subgrupo.slice(0, 5),
          atrSubgrupoId: selectedProduct.subgrupo,
          atrPerfilTarifaId: selectedProduct.perfilTarifa,
          name: user.name,
          email: user.email,
          oficinaId: selectedProduct.oficina,
          oficinaDescripcion: selectedProduct.oficinaDescripcion,
          agenteId: selectedProduct.agente,
          nombreAgente: selectedProduct.nombreAgente,
          nombrePromotor: selectedProduct.nombrePromotor,
          promotorId: selectedProduct.promotor,
        };
        const res = await Microservice(body, user.idToken, "perfilamiento", instance, accounts);
        window.open(`${selectedProduct.urlCotizador}/${res.token}`, "_blank");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeModalFunction = () => {
    setDisabledQuotationButton(false);
    closeModal();
  };
  return (
    <>
      <Form layout="vertical" form={form}>
        <Row gutter={25}>
          <SliceFields fields={fields} form={form} />
          {showAlert && !!alertMessage.length && (
            <div className="alert-info" style={{ minWidth: "100%" }}>
              <em className="fal fa-info-circle" />
              <div>
                <p style={{ fontWeight: "600" }}>Estimado agente:</p>
                {alertMessage.map((message, index) => (
                  <RenderMessage key={index} message={message} />
                ))}
              </div>
            </div>
          )}
          <Col span={24} className="form-actions">
            <Button
              data-testid="btn-cancelar-formdinamic"
              onClick={() => closeModalFunction()}
              className="outline c-2"
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              className="solid c-2"
              loading={loading}
              disabled={disabledQuotationButton}
              onClick={() => redirectCotizacion()}
              data-testid="btnCotizar"
            >
              Cotizar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FormDinamic;
