// @vendors
import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom' 
import { RichText } from 'prismic-reactjs'
import { Button } from 'antd'
import { PortalAgentesContext, PortalAgentesFormContext } from 'context/context';
import ChooseOptions from './choose-options';

// @components
import ChooseOnlyUser from 'components/choose-only-user';
import Loading from 'components/loading';
// @utisl
import { replaceAccents } from 'utils/replace-accents';
import { Microservice } from 'services/microservice';

// @styles
import './styles.scss'

const AllServices = ({ items }) => {
    const history = useHistory();
    const { handle: { setSelectedUser }, value: { user } } = useContext(PortalAgentesContext);

    const { 
		handle: { setHaveSubProducts },
        value: {  selectedProduct }
	} = useContext(PortalAgentesFormContext);

    const [loading, setLoading] = useState(true);
    const [apps, setApps] = useState({});
    const [openChooseOnlyUser, setOpenChooseOnlyUser] = useState(false);
    const [selectedService, setSelectedService] = useState({});
    const [urlService, setUrlService] = useState('');
    const [allowedUsers, setAllowedUsers] = useState([]);
    const [createToken,setCreateToken] = useState(false);
    const [openOptions, setOpenOptions] = useState(false);
    const [fields, setFields] = useState([]);

    //AQUI
    //iterar el nodo modelos en el servicio de perfilamiento
    const validateApps = (usuarios) => {

        const apis = {};

        usuarios.forEach(userMap => {
            userMap.modelos.forEach(modelo => {
                apis[modelo.cdAplicacion] = apis[modelo.cdAplicacion] ? [...apis[modelo.cdAplicacion], userMap.cdUsuario] : [userMap.cdUsuario];
            });
        });
        setApps(apis);
        setLoading(false);
    }

    const openChooseUser = (service) => {
        setSelectedService(service);
        const codeApi=service.primary.cd_aplication[0].text;
        const apiUrl=service.primary.link.url;
        const token=service.primary.enviar_token;
        setFields(service.items);

        const allowed = [];
        user.assignedUsers.forEach(u => {
            u.modelos.forEach(app => {
                if (app.cdAplicacion.toString() === codeApi) {
                    allowed.push(u);
                }
            })
        });
        setAllowedUsers(allowed);
        setUrlService(apiUrl);
        setCreateToken(token);
        setOpenChooseOnlyUser(true);
    }

    const selectUser = (cdUsuario) => {
        setSelectedUser(cdUsuario);
        setOpenChooseOnlyUser(false);

        if(fields&&fields.length){
            setOpenOptions(true);
        }
        else{
            generateToken(cdUsuario);
        }
    }

    useEffect(() => {
        if (user.assignedUsers.length) {
            validateApps(user.assignedUsers);
        }
    }, [user.assignedUsers]);

    const closeChooseUser = () => {
        setSelectedUser('');
        setHaveSubProducts(false);
        setOpenChooseOnlyUser(false);
    }

    const closeModalOptions = () => {
        setHaveSubProducts(false);
        setOpenOptions(false);
    }

    
	const generateToken = async (cdUser) => {
        const serviceName = selectedService.primary.name[0].text;

        if(serviceName === "Gestión de cartera") {
            history.push(`/gestion-cartera/${selectedProduct.agente}`);
        }
        else if (createToken) {
			const assignedUser=user.assignedUsers.find(u => u.cdUsuario === user.selectedUser?user.selectedUser:"");
            let body = {
                origin: window.location.origin,
                username: user.email,
                cdUsuario: cdUser?cdUser:user.selectedUser?user.selectedUser:"",
                rfc: assignedUser?assignedUser.rfc:"",
                sub: user.subId,
                name: user.name,
                ...(!!selectedProduct?.puntoVenta && {salesPointId: selectedProduct.puntoVenta})
            }

			//Se construye valores en token dependiendo como este matriculado en prismic
			var prismicTokens=fields&&fields.length?fields.map(x=>x.tokenmodel&&x.tokenmodel[0]&&x.tokenmodel[0].text):[];
			prismicTokens.forEach(element => {
				if(element){
					const token=JSON.parse(element);
					let keys=Object.keys(token);
					keys.forEach(key => {
						body[key]=selectedProduct[key];
					});		
				}				
			});


            const res = await Microservice(body, user.idToken, 'perfilamiento');
            window.open(`${urlService}${(urlService.indexOf('?') !== -1) ? `&token=${res.token}` : `?token=${res.token}`}`, '_blank');
            
        } else {
            window.open(`${urlService}`, '_blank');
        }
        closeModalOptions();
    }

    return (
        loading ? (
            <Loading />
        ) : (
            <>
                <ul className="list__services">
                    {items.map((service, k) => 
                         (
                            apps[service.primary.cd_aplication[0].text] ? (
                            <li
                                className="service__item"
                                key={`service-${k}`}
                                id={`service-${replaceAccents(service.primary.name[0].text)}`}>
                                <div
                                    className="service__item--header"
                                    id={`service-title-${replaceAccents(service.primary.name[0].text)}`}
                                >
                                    {service.primary.image && service.primary.image.url
                                        ? <img src={service.primary.image.url} alt={service.primary.image.alt} />
                                        : <em className={`far fa-${RichText.asText(service.primary.icon)}`} />}
                                    {RichText.asText(service.primary.name)}
                                </div>
                                <div className="service__item--body">
                                    <p className="parag"
                                        id={`service-parag-${replaceAccents(service.primary.name[0].text)}`}>
                                        {RichText.asText(service.primary.description)}
                                    </p>
                                </div>
                                <div className="service__item--actions">
                                    <Button
                                        className="solid c-2"
                                        id={`service-btn-${replaceAccents(service.primary.name[0].text)}`}
                                        onClick={() => {
                                            openChooseUser(service);
                                        }}
                                    >
                                        Entrar
                                    </Button>
                                </div>
                            </li>
                        ) : null)
                    )}
                </ul>
                <ChooseOnlyUser
                    users={allowedUsers}
                    visible={openChooseOnlyUser}
                    onClose={() => closeChooseUser()}
                    selectUser={(cdUsuario) => selectUser(cdUsuario)}
                />
                <ChooseOptions
                    open={openOptions}
                    closeModal={closeModalOptions}
                    fields={fields}
                    continueModal={generateToken}
                />
            </>
        )
    )
}

export default AllServices;